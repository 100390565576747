import Account from '../layout/Account';
import HomeLayout from '../layout/Home';

import HomePage from '../pages/Home';
import ExamQuestions from '../pages/ExamQuestions';
import MyExample from '../pages/MyExample';
import Contest from '../pages/Contest';
import Login from '../pages/Login';
import CreateExample from '../pages/CreateExample';
import Register from '../pages/Register';
import TestComponent from '../pages/TestComponent';
import VerifyEmail from '../pages/VerifyEmail';
import { routes as routesConfig } from '../config';
import EditExample from '../pages/EditExample';
import Profile from '../pages/Profile';
import ShareExample from '../pages/ShareExample';
import MyFavorite from '../pages/MyFavorite';
import ChangePassword from '../pages/ChangePassword';
import HomeAdmin from '../pages/admins/HomeAdmin';
import PreviewExample from '../pages/PreviewExample';
import MessagePage from '../pages/MessagePage';
const publicRoutes = [
    { path: routesConfig.home, component: HomePage, layout: HomeLayout },
    { path: routesConfig.login, component: Login, layout: Account },
    { path: routesConfig.register, component: Register, layout: Account },
    { path: routesConfig.verifyEmail, component: VerifyEmail, layout: Account },
    { path: routesConfig.exam, component: ExamQuestions, props: { searchMobile: true } },
    { path: routesConfig.test, component: TestComponent },
    { path: routesConfig.share, component: ShareExample },
];

const privateRoutes = [
    { path: routesConfig.conTest(), component: Contest, props: { sideBarMini: true } },
    { path: routesConfig.previewExample(), component: PreviewExample, props: { sideBarMini: true } },
    { path: routesConfig.myExam, component: MyExample },
    { path: routesConfig.editExample(), component: EditExample, props: { sideBarMini: true } },
    { path: routesConfig.profile(), layout: HomeLayout, component: Profile, props: { sideBarMini: true } },
    { path: routesConfig.changePassword, component: ChangePassword, layout: Account },
    { path: routesConfig.favorite, component: MyFavorite },
    { path: routesConfig.message, component: MessagePage, props: { noPadding: true } },
];

const adminRoutes = [
    { path: routesConfig.admin, component: HomeAdmin, layout: null },
    { path: routesConfig.createExample, component: CreateExample, props: { sideBarMini: true } },
];

export { publicRoutes, privateRoutes, adminRoutes };
