import classNames from 'classnames/bind';

import styles from './PreviewExample.module.scss';
import Button from '../../components/Button';
import Tippy from '@tippyjs/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePollVertical } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import PopupStart from '../../components/PopupStart';
import ItemRank from '../../components/ItemRank';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import request from '../../utils/request';
import chat from '../../utils/chat';
import { convertExampleToText } from '../../helpers';
import images from '../../assets/images';
import { routes } from '../../config';
const cx = classNames.bind(styles);
const initExample = [
    {
        name: 'Phần 1',
        questions: [
            {
                name: 'Có thể đề thi này bị lỗi !',
                answers: [],
            },
        ],
    },
];
function PreviewExample() {
    const { subject } = useParams();
    const [score, setScores] = useState([]);
    const [myScore, setMyScores] = useState({ user: { avatar: '' } });
    const [example, setExample] = useState([]);
    const [listExample, setListExample] = useState(initExample);
    const navigate = useNavigate();
    const handleMessage = async () => {
        try {
            await chat.post('/find-conversation', {
                slug: `exam-${example.id}`,
                name: example.name,
                image: example.image || '',
            });
            navigate(routes.message);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        request
            .get('get-score', { params: { example_id: subject } })
            .then(({ data }) => {
                setScores(data.scores);
                setMyScores(data.user_score);
            })
            .catch((err) => console.log(err));
        request
            .get('/getQuestion', { params: { id: subject } })
            .then((data) => {
                const exam = JSON.parse(data.data.question);
                setExample(data.data);
                setListExample([...exam]);
            })
            .catch((err) => {
                console.log(err);

                setListExample([...initExample]);
            });
    }, [subject]);

    return (
        <div className={cx('wraper')}>
            <div className={cx('left')}>
                <div className={cx('info', 'box')}>
                    <img src={example.image || images.defaultCard} alt=""></img>
                    <div>
                        <div className={cx('title')}>{example.name}</div>
                        <div className={cx('statistics')}>
                            <div>
                                <Tippy content="Số câu hỏi" placement="bottom">
                                    <FontAwesomeIcon color="#fca218" icon={faCircleQuestion}></FontAwesomeIcon>
                                </Tippy>
                                <p>{example.num_question}</p>
                            </div>
                            <div>
                                <Tippy content="Số lượt thi" placement="bottom">
                                    <FontAwesomeIcon color="#2cb44c" icon={faSquarePollVertical}></FontAwesomeIcon>
                                </Tippy>
                                <p>{example.count_test}</p>
                            </div>
                            <div>
                                <Tippy content="Like" placement="bottom">
                                    <FontAwesomeIcon color="rgb(10, 143, 220)" icon={faThumbsUp}></FontAwesomeIcon>
                                </Tippy>
                                <p>{example.count_like}</p>
                            </div>
                        </div>
                        <div className={cx('actions')}>
                            <PopupStart idExample={subject} trigger={<Button primary>Bắt đầu</Button>}></PopupStart>
                            <Button onClick={handleMessage} primary>
                                Nhắn tin
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={cx('author', 'box')}>
                    <div
                        className={cx('word_example')}
                        dangerouslySetInnerHTML={{ __html: convertExampleToText(listExample, '<br>', 2) }}
                    ></div>
                </div>
            </div>
            <div className={cx('rank', 'box')}>
                <div className={cx('title')}>Bảng xếp hạng</div>
                <hr></hr>
                <div className={cx('rank-content')}>
                    <div className={cx('me')}>
                        <div className={cx('core')}>
                            <img className={cx('avatar')} src={myScore.user.avatar} alt="Hình nền"></img>
                            {myScore.ranking ? `No.${myScore.ranking}` : 'Không có xếp hạng'}
                        </div>
                    </div>
                    {score.length > 0 &&
                        score.map((item, index) => {
                            return (
                                <ItemRank key={index} point={item.score} avatar={item.user.avatar} rank={index + 1}>
                                    {item.user.name}
                                </ItemRank>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}

export default PreviewExample;
