import classNames from 'classnames/bind';
import styles from './ItemRank.module.scss';

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, faTrophy } from '@fortawesome/free-solid-svg-icons';
import Button from '../Button';
const cx = classNames.bind(styles);
function ItemRank({ rank = 1, point = 0, avatar, children }, ref) {
    const style = { no1: rank === 1, no2: rank === 2, no3: rank === 3 };

    return (
        <div className={cx('wrapper', style)}>
            <h1>{rank}</h1>
            <img className={cx('avatar')} src={avatar} alt="Hình nền"></img>
            <div className={cx('name')}>{children}</div>
            <div className={cx('point')}>{point}đ</div>
            {rank <= 3 ? (
                <FontAwesomeIcon className={cx('cup', style)} icon={faTrophy}></FontAwesomeIcon>
            ) : (
                <FontAwesomeIcon className={cx('cup', style)} icon={faTrophy}></FontAwesomeIcon>
            )}
            <Button icon={faMessage} className={cx('button')}></Button>
        </div>
    );
}

export default ItemRank;
