// redux/messageSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initState = {
    arrayMessage: [],
    selectedMessage: {
        conversationId: null,
        message: [],
    },
};

const messageSlice = createSlice({
    name: 'message',
    initialState: initState,
    reducers: {
        addMessage: (state, action) => {
            state.selectedMessage.message = [...action.payload.messages, ...state.selectedMessage.message];
        },
        newMessage: (state, action) => {
            const conversationId = action.payload.conversationId;
            if (state.selectedMessage.conversationId === conversationId) {
                state.selectedMessage.message = [...state.selectedMessage.message, action.payload];
            } else {
                const message = state.arrayMessage.find((c) => c.conversationId === conversationId);
                if (message) {
                    message.message = [...message.message, action.payload];
                }
            }
        },
        setSelectedMessage: (state, action) => {
            let message = state.arrayMessage.find((c) => c.conversationId === action.payload);
            let s = state.arrayMessage.find((c) => c.conversationId === state.selectedMessage.conversationId);
            if (s) {
                s.message = state.selectedMessage.message;
            }
            if (message) {
                state.selectedMessage = message;
            } else {
                state.arrayMessage.push({
                    conversationId: action.payload,
                    message: [],
                });
                state.selectedMessage = {
                    conversationId: action.payload,
                    message: [],
                };
            }
        },
    },
});

export const { addMessage, setSelectedMessage, newMessage } = messageSlice.actions; // Export actions
export default messageSlice.reducer; // Export reducer
