import classNames from 'classnames/bind';
import styles from './Conversation.module.scss';

import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Button from '../Button';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { addCoversation, setSelectConversation } from '../../redux/slices/conversationSlice';
import chat from '../../utils/chat';
import { setSelectedMessage } from '../../redux/slices/messageSlice';
import images from '../../assets/images';
const cx = classNames.bind(styles);
function Conversation({ setOpenMessage }) {
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const scrollableDivRef = useRef();
    const conversations = useSelector((state) => state.conversation.conversations) ?? [];
    const selectId = useSelector((state) => state.conversation.selectedConversation._id);

    const loadMoreData = () => {
        if (loading || !scrollableDivRef.current) {
            return;
        }

        const scrollableDiv = scrollableDivRef.current;
        const previousScrollHeight = scrollableDiv.scrollHeight;

        setLoading(true);
        const getConversation = async () => {
            try {
                const { data: conversation } = await chat.get('/get-conversation', {
                    params: { skip: conversations.length },
                });
                if (conversation.length < 10) {
                    setHasMore(false);
                }
                // dispatch(addMessages({ conversationId: conversation._id, messages: data }));
                setTimeout(() => {
                    if (scrollableDiv) {
                        const newScrollHeight = scrollableDiv.scrollHeight;
                        scrollableDiv.scrollTop -= newScrollHeight - previousScrollHeight;
                    }
                }, 0);

                setLoading(false);
                dispatch(addCoversation(conversation));
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };
        getConversation();
    };
    const dispatch = useDispatch();
    const handleChooseConversation = (id) => {
        setOpenMessage(true);
        dispatch(setSelectConversation(id));
        dispatch(setSelectedMessage(id));
    };
    useEffect(() => {
        // socket.emit('join-room', conversation._id);
        loadMoreData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className={cx('wrapper')}>
            <div className={cx('header')}>
                <div className={cx('title')}>Đoạn chat</div>
                <div className={cx('search')}>
                    <input className={cx('input')} placeholder="Tìm kiếm hội thoại"></input>
                    <Button icon={faSearch}></Button>
                </div>
            </div>
            <div id={`scrollableDivConversation`} ref={scrollableDivRef} className={cx('content')}>
                <InfiniteScroll
                    dataLength={conversations.length}
                    next={loadMoreData}
                    hasMore={hasMore}
                    style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                    //inverse={true}
                    loader={<div>Đang tải ...</div>}
                    endMessage={<div>Đã tải hết tin nhắn 🤐</div>}
                    scrollableTarget={`scrollableDivConversation`}
                >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {conversations.map((item, index) => {
                            const classes = {
                                active: item._id === selectId,
                            };

                            return (
                                <div
                                    onClick={() => {
                                        handleChooseConversation(item._id);
                                    }}
                                    className={cx('conversation_item', classes)}
                                    key={index}
                                >
                                    <img
                                        className={cx('avatar')}
                                        src={item.image || images.defaultAvatar}
                                        alt="Ảnh"
                                    ></img>
                                    <div className={cx('name')}>{item.name}</div>
                                </div>
                            );
                        })}
                    </div>
                </InfiniteScroll>
            </div>
        </div>
    );
}

export default Conversation;
