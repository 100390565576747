import { useSelector } from 'react-redux';
function TestComponent() {
    const counter = useSelector((state) => state.conversation.value);
    return (
        <div>
            <h1>{counter}</h1>
            <button onClick={() => {}}>Thêm</button>
        </div>
    );
}

export default TestComponent;
