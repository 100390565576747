import { io } from 'socket.io-client';

export const socket = io(process.env.REACT_APP_CHAT_API, {
    withCredentials: true,
    autoConnect: false, // Chỉ kết nối khi cần
});

export const connectSocket = () => {
    if (!socket.connected) socket.connect();
};

export const disconnectSocket = () => {
    if (socket.connected) socket.disconnect();
};
