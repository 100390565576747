// redux/conversationSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initState = {
    conversations: [],
    selectedConversation: {
        _id: null,
    },
};

const conversationSlice = createSlice({
    name: 'conversation',
    initialState: initState,
    reducers: {
        addCoversation: (state, action) => {
            state.conversations.push(...action.payload);
        },
        setSelectConversation: (state, action) => {
            const conversation = state.conversations.find((c) => c._id === action.payload);
            state.selectedConversation = conversation;
        },
    },
});

export const { addCoversation, setSelectConversation } = conversationSlice.actions; // Export actions
export default conversationSlice.reducer; // Export reducer
