import { createContext, useContext, useMemo, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Popup from 'reactjs-popup';
import Button from '../components/Button';

const GlobalContext = createContext();
export const GlobalProvider = ({ children }) => {
    const [popup, setPopup] = useState({
        state: false,
        message: '123',
        next: () => {},
        accecpt: () => {},
        cancel: () => {},
        accecptM: 'Chấp nhận',
        cancelM: 'Hủy',
    });
    const showToast = (message, props = {}) => {
        toast(message, { autoClose: 2000, ...props });
    };

    const toastPromise = function (message = 'Waiting promise...') {
        const toastId = toast.loading(message);
        return {
            success(message = 'success', props = {}) {
                toast.update(toastId, {
                    render: message,
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000,
                    ...props,
                });
                return toastId;
            },
            error(message = 'Something went wrong', props = {}) {
                toast.update(toastId, { render: message, type: 'error', isLoading: false, autoClose: 3000, ...props });
            },
        };
    };
    const popupWarning = ({
        accecptM = 'Chấp nhận',
        cancelM = 'Hủy',
        message,
        next = () => {},
        accecpt = () => {},
        cancel = () => {},
    }) => {
        const event = { next, accecpt, cancel, cancelM, accecptM, message };
        setPopup((prev) => ({ ...prev, state: true, ...event }));
    };
    const value = useMemo(() => ({ showToast, popupWarning, toastPromise }), []);
    return (
        <>
            <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
            <ToastContainer draggable></ToastContainer>
            <Popup
                open={popup.state}
                onClose={() => {
                    popup.next();
                    setPopup((prev) => ({ ...prev, state: false }));
                }}
            >
                {popup.message}
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        primary
                        onClick={() => {
                            setPopup((prev) => ({ ...prev, state: false }));
                            popup.next();
                            popup.accecpt();
                        }}
                    >
                        {popup.accecptM}
                    </Button>
                    <Button
                        danger
                        onClick={() => {
                            setPopup((prev) => ({ ...prev, state: false }));
                            popup.next();
                            popup.cancel();
                        }}
                    >
                        {popup.cancelM}
                    </Button>
                </div>
            </Popup>
        </>
    );
};

export const useGlobalContext = () => {
    return useContext(GlobalContext);
};
