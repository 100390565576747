import { configureStore } from '@reduxjs/toolkit';
import conversationReducer from './slices/conversationSlice';
import messageReducer from './slices/messageSlice';
const store = configureStore({
    reducer: {
        conversation: conversationReducer,
        message: messageReducer,
    },
});

export default store;
