import classNames from 'classnames/bind';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
    faCircleQuestion,
    faClipboardQuestion,
    faHeart,
    faHome,
    faRankingStar,
} from '@fortawesome/free-solid-svg-icons';

import styles from './SideBar.module.scss';
import NavList from '../NavList';
import routes from '../../../config/routes';
const cx = classNames.bind(styles);

const initMenu = [
    {
        title: 'Trang chủ',
        icon: faHome,
        event: {
            to: routes.home,
        },
    },
    {
        title: 'Đề thi',
        icon: faClipboardQuestion,
        event: {
            to: routes.exam,
        },
    },
    {
        title: 'Đề thi của tôi',
        icon: faCircleQuestion,
        event: {
            to: routes.myExam,
        },
    },
    {
        title: 'Đề thi yêu thích',
        icon: faHeart,
        event: {
            to: routes.favorite,
        },
        spacer: true,
    },
    {
        title: 'Tin nhắn',
        icon: faFacebookMessenger,
        event: {
            to: routes.message,
        },
    },
    {
        title: 'Xếp hạng',
        icon: faRankingStar,
        spacer: true,
    },
    {
        title: 'Trang chủ của trường',
        event: {
            href: 'https://hubt.vnedu.vn/',
        },
    },
    {
        title: 'Liên hệ chúng tôi',
    },
    {
        title: 'Câu hỏi thường gặp',
    },
    {
        title: 'Điều khoản sử dụng',
    },
    {
        title: 'Hướng dẫn sử dụng',
    },
    {
        title: 'Chính sách bảo mật',
    },
    {
        title: '© 2024 - 2025 Hugn',
    },
];

function SideBar({ stateSideBar, sideBarMini }) {
    const { pathname } = useLocation();
    const [menus, setMenus] = useState(initMenu);
    useEffect(() => {
        const newMenu = [...menus];
        menus.map((item, index) => {
            if (item.event) {
                if (item.active) {
                    newMenu[index] = { ...newMenu[index], active: false };
                }
                if (pathname === item.event.to) {
                    newMenu[index] = { ...newMenu[index], active: true };
                    setMenus(newMenu);
                }
            }
            return null;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    const classes = cx('wraper', {
        active: stateSideBar,
        hide: sideBarMini && !stateSideBar,
    });
    return (
        <div className={classes}>
            <NavList classNameButton={cx('button')} menus={menus}></NavList>
        </div>
    );
}
export default SideBar;
